import * as React from "react"
import Particles from "react-tsparticles"
import "../css/styles.css"
import Layout from "../components/Layout"



// markup
const IndexPage = () => {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <>
      <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#2F3038",
          },
        },
        fpsLimit: 60,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 2 ,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
      style={{
        backgroundColor: "#252527",
        position: "relative",
        width: "100%",
        height: "100%"
      }}
    />
    <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <title>Astoria | Landing Page</title>
        <link rel="icon" type="image/x-icon" href="../assets/favicon.ico" />
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css" rel="stylesheet" type="text/css" />
        <link href="https://fonts.googleapis.com/css2?family=Electrolize&family=Open+Sans:wght@700&family=Poppins:wght@300;400&display=swap" rel="stylesheet"/>
        <link href="../css/styles.css" rel="stylesheet" />
    </head>
    <body>

      <div class="container-1">
        <div class="logo">astoria</div>
      </div>

      <div class="flex-container">

        <div class="description">
          <div class="header"> All your digital assets. <br /> All in one place.</div>
          <div class="sub-text">Aggregated Real-Time Portfolio Monitoring <br/> Full Cross-Chain Compatibility <br/> Customizable Analytics & Reporting </div>
        </div>

        
        <div class="submission-area">
          <form class = "submission-form" name="emailform" method="POST" action="https://getform.io/f/d64f868b-1daa-4e8f-82d3-06a04a6a4cc0">
                  <input class="input-area" name="emailAddress" type="email" placeholder="Enter Email to Join Beta Waitlist" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                  <button class="btn" id="submitButton" type="submit">Sign Up</button>
          </form>
        </div>

      </div>
        
  

        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
        <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
    </body>
    </>
  )
}

export default IndexPage
